<template>
  <div>
    <kn-form-subtitle title="Agregar sucursal" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Número de sucursal:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="branch.numero_sucursal"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="pt-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Nombre de sucursal:</v-subheader>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-model="branch.nombre_sucursal"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-address
        :address="branchAddress"
        :fillFromSearch="toUpdate"
      />
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de sucursales"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeBranches">
          <v-list-item :key="item.nombre_salon">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === branch.id"
                  class="mb-0 pb-0"
                  v-model="branch.numero_sucursal"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.numero_sucursal"
                />
                <v-text-field
                  v-if="toUpdate && item.id === branch.id"
                  class="mb-0 pb-0"
                  v-model="branch.nombre_sucursal"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_sucursal"
                />
                <kn-address
                  v-if="toUpdate && item.id === branch.id"
                  :address="branchAddress"
                  :fillFromSearch="toUpdate"
                />
                <v-list-item-subtitle v-else>
                  {{ fullAddress(item.direccion_sucursal) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === branch.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeBranches.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnAddress: () => import('@/components/forms/KnAddress.vue') 
  },
  mixins: [addressUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      branch: {
        id: 'NA', // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      branchAddress: {
        id: 'NA', // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
        id_autor: null,
        id_institucion_educativa: null
      },
      groups: [],
      classrooms: [],
      branches: [],
      schoolCycle: [],
      toUpdate: false,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeBranches() {
      return this.branches.filter(branch => branch.direccion_sucursal.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.branch) || this.objectHasNulls(this.branchAddress)
    }
  },
  async created() {
    this.branch.id_autor = this.userData.id
    this.branch.id_institucion_educativa = this.institutionId
    this.branchAddress.id_autor = this.userData.id
    this.branchAddress.id_institucion_educativa = this.institutionId
    this.branches = await this.fetchBranchesByEI(this.institutionId)
  },
  methods: {
    async fetchBranchesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/sucursal-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener sucursales por institucion educativa', error);
      }
    },
    async createBranch() {
      try {
        const response = await api.post('/personas/crear-sucursal', {
          numero_sucursal: this.branch.numero_sucursal,
          nombre_sucursal: this.branch.nombre_sucursal,
          id_direccion: this.branch.id_direccion,
          id_institucion_educativa: this.branch.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la sucursal.')
        }
      } catch (error) {
        console.error('Error al intentar crear la sucursal', error)
        this.errors.push('Error al intentar crear la sucursal')
      }
    },
    async updateBranch() {
      try {
        const response = await api.patch('/personas/update-sucursal', {
          id: this.branch.id,
           numero_sucursal: this.branch.numero_sucursal,
          nombre_sucursal: this.branch.nombre_sucursal,
          id_direccion_sucursal: this.branchAddress.id,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la sucursal')
        }
      } catch (error) {
        console.error('Error al intentar actualizar la sucursal', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteBranch(sucursalId) {
      try {
        const response = await api.post('/personas/delete-sucursal', {
          id_sucursal: sucursalId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la sucursal')
        }
      } catch (error) {
        console.error('Error al intentar eliminar la sucursal', error);
      }
    },
    async createAddress() {
      try {
        const response = await api.post('/personas/crear-direccion', this.branchAddress)
        const responseData = await response.data
        // console.log('Respuesta direccion: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo crear la dirección: ' + responseData.e.join(','))
          return
        }
        return responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear direccion')
        console.error('Error al intentar crear direccion.', error);
      }
    },
    async updateAddress() {
      try {
        const response = await api.patch('/personas/update-direccion', this.branchAddress)
        const responseData = await response.data
        // console.log('Respuesta actualizar direccion: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo actualizar la dirección: ' + responseData.e.join(','))
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar direccion')
        console.error('Error al intentar actualizar direccion.', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteAddress(addressId) {
      try {
        const response = await api.post('/personas/delete-direccion', {
          id_direccion: addressId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la dirección')
        }
      } catch (error) {
        console.error('Error al intentar eliminar la dirección', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        this.branchAddress.id_institucion_educativa = this.institutionId
        await this.updateAddress()
        await this.updateBranch()
        this.toUpdate = false
      } else {
        // console.log('Primero vamos a crear la dirección: ', this.branchAddress);
        this.branchAddress.id_institucion_educativa = this.institutionId
        const addressId = await this.createAddress()
        this.branch.id_direccion = addressId
        // console.log('Siguiente vamos a crear la sucursal: ', this.branch);
        await this.createBranch()
        this.clear()
      }
      this.branches = await this.fetchBranchesByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.branch = {
        id: 'NA', // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.branchAddress = {
        id: 'NA', // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.branch = {
        id: 'NA', // No asignado
        numero_sucursal: null,
        nombre_sucursal: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.branchAddress = {
        id: 'NA', // No asignado
        estado: null,
        ciudad: null,
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async edit(item) {
      this.branch = {...item}
      const zip = await this.fetchZipById(item.direccion_sucursal.codigo_postal)
      // console.log('codigo postal encontrado:', zip);
      this.branchAddress = {...item.direccion_sucursal}
      this.branchAddress.codigo_postal = {...zip}
      // console.log('Item a editar:', item);
      this.toUpdate = true
    },
    async deleteInline(item) {
      await this.deleteAddress(item.direccion_sucursal.id)
      await this.deleteBranch(item.id)
      this.groups = await this.fetchBranchesByEI(this.institutionId)
      this.clearInline()
    },
    fullAddress({calle: ca,
      numero_exterior: ne,
      numero_interior: ni,
      colonia: col,
      ciudad: ci,
      estado: es
    }) {
      const fullAddress = `${ca} ${ne}${ni ? ' '+ni : ''}, Col. ${col}, ${ci}, ${es}.`
      return fullAddress
    }
  },
}
</script>

<style>

</style>